import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/home/index"),
  },
  {
    path: "/analyze",
    name: "analyze",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/analyze/index"),
  },
  {
    path: "/result",
    name: "result",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/result/index"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
