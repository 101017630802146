export default {
  home: "Home",
  analyze: "Analyze",
  title: "Disease warning platform",
  bgTag: "Easy to use and professional",
  bgTag2: "Chronic disease early warning cloud platform",
  bgTag3:
    "Real-time analysis, accurate insight, focus on chronic disease analysis, help health",
  bgBtn: "start analysis",

  cd1Title: "Primary care is under pressure",
  cd1Content:
    "A strong primary care system is essential for disease prevention, detection and management, and health promotion. However, there are large gaps in the coverage of health services in many areas, especially in poor and marginalized areas due to the limited medical conditions that make disease prevention, treatment and health management insufficient. According to statistics, half of the world's population is still unable to obtain the health care services they need, and about 500 million people are pushed into extreme poverty due to the high cost of health care. An imbalance in health screening services means that many NCDs and preventable diseases go untreated. Noncommunicable diseases are now responsible for 71 percent of global deaths, 82 percent of which occur in low- and middle-income countries. The epidemic of NCDs imposes a heavy economic burden and devastating health consequences on individuals, families, and countries, and can exacerbate the risk of communicable disease transmission and overwhelm health systems. Therefore, the development and promotion of an affordable and universally affordable grass-roots disease early warning strategy is critical for tackling NCDs and reducing preventable global deaths.",
  cd2Title: "Chronic diseases are like boiling frogs",
  cd2Content:
    "Non-communicable diseases (NCDs) are also known as chronic diseases. Chronic diseases refer to a group of diseases mainly represented by cancer, cardiovascular diseases, chronic respiratory diseases, and diabetes, as well as mental diseases, neurodegenerative diseases, and obesity. Such diseases have the characteristics of long course, complex etiology, serious health damage and social harm. The most widely studied chronic non-communicable diseases are cardiovascular and cerebrovascular diseases and cancer. Cardiovascular diseases (CVDs) are the major chronic diseases, accounting for half of chronic disease mortality and the leading cause of death in the world [8]. Cardiovascular diseases include heart disease, cerebrovascular disease, and vascular disease. They are often referred to as 'silent killers' because of their insidious disease. After the onset, complex surgical operations such as coronary artery bypass grafting, heart transplantation, or artificial hearts are required in a timely manner. Patients in low- and middle-income areas often fail to receive timely treatment due to financial burdens or local medical service limitations, increasing the risk of death. Early detection of disease risk in the primary care system can prevent many cardiovascular diseases with inexpensive treatments.",
  ytTitle: "peculiarity",
  ytContent:
    "This project addresses the actual needs of primary health screening. Plan to develop an artificial intelligence disease analysis early warning platform. Based on the machine learning algorithm model, analyze the blood routine and blood biochemical indicators in clinical medical data, establish a chronic disease early warning system, and give early warning of disease occurrence. And realize disease identification. As an effective supplement to conventional disease screening methods, it assists doctors in disease prediction, classification and management of patients. To explore the possibility of disease auxiliary screening strategies based on blood routine testing, the aim is to build a regional national health information platform, promote the interconnection of information systems among grass-roots medical and health institutions, superior medical and health institutions, disease control and other professional public health institutions, and provide supporting conditions for grass-roots chronic disease information sharing and remote service integrated medical and prevention management.",
  yt2Title: "advantage",
  yt2CardTitle1: "Visual analysis",
  yt2CardContent1:
    "Visual analytics can help healthcare professionals identify early signs and trends of chronic diseases, enabling early intervention and treatment.",
  yt2CardTitle2: "Early risk identification",
  yt2CardContent2:
    "Machine learning algorithms can analyze patients' physiological, biochemical data and medical records to identify potential risk factors for chronic diseases in advance, enabling early risk identification.",
  yt2CardTitle3: "Preventive intervention",
  yt2CardContent3:
    "By analyzing large-scale data, machine learning can identify common characteristics in patient populations, providing guidance for the development of preventive interventions to slow the progression of chronic diseases.",
  yt2CardTitle4: "Intelligent diagnostic aid",
  yt2CardContent4:
    "Machine learning models can help doctors make diagnoses, provide more accurate judgments and recommendations, and help medical professionals better understand their patients' health.",
  footerText:
    "Online Web application of chronic disease prediction based on machine learning",
  suggestion: [
    {
      label: 0,
      content: [
        {
          title:
            "Regular health checkups:  Maintain regular health checkups to ensure that potential health problems are detected early and prompt action is  taken.",
        },
        {
          title:
            "Personalized health Plan:  Consult a professional doctor to develop a personalized health management plan to address different health risks.",
        },
        {
          title:
            "Healthy lifestyle: Maintain a healthy lifestyle, including a balanced diet, adequate physical exercise, adequate sleep, etc.",
        },
        {
          title:
            "Disease screening: Screening and examination for related diseases, as recommended by a doctor, and timely detection and treatment of underlying health problems.",
        },
        {
          title:
            "Medication management: If necessary, use the medication appropriately as prescribed by the doctor to control the symptoms of the relevant disease.",
        },
      ],
    },
    {
      label: 1,
      content: [
        {
          title:
            "Personalized treatment plan: Develop a personalized treatment plan based on an individual's health status, focusing on moderate risk factors, along with a comprehensive health management approach.",
        },
        {
          title:
            "Regular monitoring: Perform regular health monitoring, including blood tests, tumor marker testing, etc., to detect any potential problems early.",
        },
        {
          title:
            "Professional medical team: The formation of professional medical teams, including professional doctors in different fields, to ensure the comprehensive management and treatment of different diseases.",
        },
        {
          title:
            "Medication management: Rational use of medications as needed to control chronic diseases such as hypertension and diabetes, while focusing on treatments that may be related to cancer.",
        },
        {
          title:
            "Healthy lifestyle: Emphasis on a healthy lifestyle, including a good diet, moderate exercise, smoking and drinking to improve overall health.",
        },
      ],
    },
    {
      label: 2,
      content: [
        {
          title:
            "Multidisciplinary medical team: Assemble a multidisciplinary medical team, including neurologists, rheumatologists, cardiologists, hematologists, endocrinologists, oncologists, etc., to ensure comprehensive medical care.",
        },
        {
          title:
            "Personalized treatment Plan: Develop an individualized treatment plan that takes into account the characteristics and interrelationships of multiple diseases to provide comprehensive treatment and management.",
        },
        {
          title:
            "Regular monitoring and screening: Get regular health monitoring, including blood tests, heart function tests, cancer markers, etc., as well as early detection and timely management of potential problems.",
        },
        {
          title:
            "Medication management: The appropriate use of medications to control symptoms, reduce pain, and delay disease progression, according to the characteristics of each disease.",
        },
        {
          title:
            "Preventive measures: Emphasis on disease prevention, including vaccination, regular medical check-ups, cancer screenings, etc., to reduce the risk of disease.",
        },
      ],
    },
  ],
  hightD0: "",
  mediumD0:
    "leukaemia，diabetes，Liver cancer，Gastric cancer，adenocarcinoma，hypertension",
  lowD0: "parkinsonism，Systemic lupus erythematosus，cardiomyopathy",
  hightD1: "",
  mediumD1: "parkinsonism，Systemic lupus erythematosus，cardiomyopathy",
  lowD1:
    "leukaemia，diabetes，Liver cancer，Gastric cancer，adenocarcinoma，hypertension",
  hightD2:
    "parkinsonism，Systemic lupus erythematosus，cardiomyopathy，leukaemia，diabetes，Liver cancer，Gastric cancer，adenocarcinoma，hypertension",
  mediumD2: "",
  lowD2: "",
  titleRe: "Analysis result",
  titleR2: "Doctor's advice",
  d1s: "Low risk",
  d2s: "Medium risk",
  d3s: "High risk",
  d1sw: "Low-risk disease",
  d2sw: "Medium risk disease",
  d3sw: "High-risk disease",
  bb1: "WBC",
  bb2: "NE",
  bb3: "Lym",
  bb4: "Mono",
  bb5: "EO",
  bb6: "Baso",
  bb7: "RBC",
  bb8: "HGB",
  bb9: "HCT",
  bb10: "MCV",
  bb11: "MCH",
  bb12: "MCHC",
  bb13: "RDW-CV",
  bb14: "PLT",
  bb15: "MPV",
  sjfx: "Data Analysis ",
  scsj: "Upload Data ",
  txsj: "Fill in the data ",
  clear: "All clear ",
  subs: "Submit analysis ",
};
