<template>
  <div class="container">
    <div class="header">
      <div class="navMenu">
        <div class="logo">
          <img src="https://www.hsydata.com/img/logof.61dd6b04.png" alt="" />
          <span>{{ $t("title") }}</span>
        </div>
        <div class="menu">
          <span @click="jumpHome">{{ $t("home") }}</span>
          <span @click="jump">{{ $t("analyze") }}</span>
        </div>
        <div class="bread" @click="hoverList">
          <img src="@/assets/menu.png" alt="" />
        </div>

        <!-- <div class="en-zh">
          <p @click="toEn" v-show="$i18n.locale == 'zh'">English</p>
          <p @click="toZh" v-show="$i18n.locale == 'en'">中文</p>
        </div> -->
      </div>
      <div :class="{ swiperDown: true, active: isActive }">
        <ul>
          <li @click="jumpHome">
            <p>{{ $t("home") }}</p>
          </li>
          <li @click="jump">
            <p>{{ $t("analyze") }}</p>
          </li>
          <!-- <li>
            <p @click="toEn" v-show="$i18n.locale == 'zh'">English</p>
            <p @click="toZh" v-show="$i18n.locale == 'en'">中文</p>
          </li> -->
        </ul>
      </div>
    </div>
    <router-view />
    <p class="adfiv">
      If you have any questions, please contact dzb20@nudt.edu.cn.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    hoverList() {
      if (this.isActive) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    },

    jumpHome() {
      this.isActive = false;
      this.$router
        .push({
          path: "/",
        })
        .catch(() => {});
    },
    jump() {
      this.isActive = false;
      this.$router
        .push({
          path: "/analyze",
        })
        .catch(() => {});
    },
    toEn() {
      this.$i18n.locale = "en";
    },
    toZh() {
      this.$i18n.locale = "zh";
    },
  },
};
</script>



<style lang="less">
.adfiv {
  position: fixed;
  top: 50%;
  width: 180px;
  right: 10px;
  text-align: center;
  border-radius: 5px;
  color: #000;
  transform: translateY(-50%);
  z-index: 9999999999;
  background-color: hsla(0, 0%, 93%, 0.5);
}
* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.container {
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
  z-index: 99999999;

  .navMenu {
    background: rgba(255, 255, 255, 0);
    position: relative;
    max-width: 1200px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-left: 10px;

      img {
        width: 60px;
        height: 60px;
      }

      span {
        margin-left: 10px;
      }
    }

    .menu {
      margin-left: 30px;

      span {
        cursor: pointer;
        padding: 20px 35px 20px 35px;
      }

      span:hover {
        background-color: whitesmoke;
      }

      @media screen and (max-width: 720px) {
        display: none;
      }
    }

    .en-zh {
      position: absolute;
      right: 10px;
      display: inline-block;

      @media screen and (max-width: 720px) {
        display: none;
      }
    }

    .bread {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      display: none;

      @media screen and (max-width: 720px) {
        display: inline-block;
      }

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .swiperDown {
    display: none;
    transition: 0.3s all;

    @media screen and (max-width: 720px) {
      display: inline-block;
      width: 100%;
      height: 0px;
      position: absolute;
      overflow: hidden;
      top: 60px;
      left: 0;
      z-index: 99999999;

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        li {
          width: 100%;
          background: rgba(255, 255, 255, 0.9);
          height: 50px;
          display: flex;
          align-items: center;

          p {
            width: 100%;

            margin: 0 0 0 15px;
          }
        }

        li:hover {
          background-color: whitesmoke;
        }
      }
    }
  }

  .active {
    height: 150px;
  }
}
</style>