import { render, staticRenderFns } from "./App.vue?vue&type=template&id=746748e9"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=746748e9&prod&lang=less"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_css-loader@6.9.0_vue-template-compiler@2.7.16_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports