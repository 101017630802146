export default {
  home: "首页",
  analyze: "分析",
  title: "疾病预警平台",
  bgTag: "易用、专业的",
  bgTag2: "慢性病早期预警云平台",
  bgTag3: "实时分析，精准洞察，专注慢性病分析，助力身体健康",
  bgBtn: "开始分析",
  cd1Title: "初级保健面临压力",
  cd1Content:
    "强大的初级保健系统对于疾病预防、发现和管理以及健康促进至关重要。然而，在许多地区，特别是在贫穷和边缘地区，由于医疗条件有限，疾病预防、治疗和健康管理不足，保健服务的覆盖面存在很大差距。据统计，世界上仍有一半人口无法获得所需的卫生保健服务，约有5亿人因卫生保健费用高昂而陷入极端贫困。健康筛查服务的不平衡意味着许多非传染性疾病和可预防疾病得不到治疗。非传染性疾病目前造成全球71%的死亡，其中82%发生在低收入和中等收入国家。非传染性疾病的流行给个人、家庭和国家带来了沉重的经济负担和毁灭性的健康后果，并可能加剧传染病传播的风险，使卫生系统不堪重负。因此，制定和促进负担得起且普遍负担得起的基层疾病预警战略，对于应对非传染性疾病和减少可预防的全球死亡至关重要。",
  cd2Title: "慢性病就像煮青蛙",
  cd2Content:
    "非传染性疾病(NCDs)也被称为慢性病。慢性病是指以癌症、心血管疾病、慢性呼吸系统疾病、糖尿病为主要代表的一类疾病，以及精神疾病、神经退行性疾病、肥胖症等。该类疾病病程长、病因复杂、健康损害严重、社会危害性大。研究最广泛的慢性非传染性疾病是心脑血管疾病和癌症。心血管疾病(cvd)是主要的慢性疾病，占慢性病死亡率的一半，是世界上主要的死亡原因[8]。心血管疾病包括心脏病、脑血管病和血管病。他们通常被称为“沉默的杀手”，因为他们潜伏的疾病。发病后需及时行冠状动脉搭桥术、心脏移植或人工心脏等复杂手术。低收入和中等收入地区的患者往往由于经济负担或当地医疗服务的限制而无法得到及时治疗，增加了死亡风险。在初级保健系统中早期发现疾病风险可以通过廉价的治疗预防许多心血管疾病。",
  ytTitle: "特点",
  ytContent:
    "本项目针对基层健康筛查的实际需求。规划开发人工智能疾病分析预警平台，基于机器学习算法模型，分析临床医学数据中的血常规和血液生化指标，建立慢性病预警系统，对疾病发生进行预警。并实现疾病识别。作为常规疾病筛查方法的有效补充，它协助医生对患者进行疾病预测、分类和管理。探索基于血常规检测的疾病辅助筛查策略的可能性，旨在打造区域性国家卫生信息平台，促进基层医疗卫生机构、上级医疗卫生机构、疾控等专业公共卫生机构之间的信息系统互联互通，为基层慢性病信息共享和远程服务提供支撑条件医防一体化管理。",
  yt2Title: "优势",
  yt2CardTitle1: "可视化分析",
  yt2CardContent1:
    "可视化分析可以帮助医疗专业人员识别慢性疾病的早期迹象和趋势，使得早期干预和治疗成为可能。",
  yt2CardTitle2: "早期风险识别",
  yt2CardContent2:
    "机器学习算法能够分析患者的生理、生化数据和医疗记录，提前发现慢性疾病的潜在风险因素，实现早期风险识别。",
  yt2CardTitle3: "预防干预措施",
  yt2CardContent3:
    "通过分析大规模的数据，机器学习可以识别患者群体中的共同特征，为制定预防干预措施提供指导，减缓慢性疾病的发展。",
  yt2CardTitle4: "智能诊断辅助",
  yt2CardContent4:
    "机器学习模型可以辅助医生进行诊断，提供更准确的判断和建议，帮助医疗专业人员更好地理解患者的健康状况。",
  footerText: "基于机器学习的慢病预测在线Web应用",
  suggestion: [
    {
      label: 0,
      content: [
        {
          title:
            "定期健康检查：保持定期的健康检查，以确保及早发现潜在的健康问题，并及时采取措施。",
        },
        {
          title:
            "个性化的健康计划：咨询专业医生，制定个性化的健康管理计划，以应对不同的健康风险。",
        },
        {
          title:
            "健康生活方式：保持健康的生活方式，包括均衡饮食、适量的体育锻炼、充足的睡眠等。",
        },
        {
          title:
            "疾病筛查：根据医生的建议进行相关疾病的筛查和检查，以及时发现和治疗潜在的健康问题。",
        },
        {
          title:
            "药物管理：如果有需要，按照医生的处方合理使用药物，以控制相关疾病的症状。",
        },
      ],
    },
    {
      label: 1,
      content: [
        {
          title:
            "个性化治疗计划： 制定基于个体健康状况的个性化治疗计划，重点关注中风险因素，同时采取综合性的健康管理措施。",
        },
        {
          title:
            "定期监测： 进行定期的健康监测，包括血液检查、肿瘤标记物检测等，以便及早发现任何潜在的问题。",
        },
        {
          title:
            "专业医疗团队： 组建专业医疗团队，涵盖不同领域的专业医生，以确保对不同疾病的全面管理和治疗。",
        },
        {
          title:
            "药物管理： 根据需要，合理使用药物来控制高血压、糖尿病等慢性疾病，同时关注可能与癌症相关的治疗。",
        },
        {
          title:
            "健康生活方式： 强调健康生活方式，包括良好的饮食、适量的运动、戒烟戒酒等，以提高整体健康水平。",
        },
      ],
    },
    {
      label: 2,
      content: [
        {
          title:
            "多学科医疗团队： 组建一个多学科的医疗团队，包括神经科医生、风湿病专家、心脏病专家、血液学家、内分泌学家、肿瘤学家等，以确保全面的医疗关怀。",
        },
        {
          title:
            "个性化治疗计划： 制定个体化的治疗计划，综合考虑多个疾病的特点和相互关系，以提供全面的治疗和管理。",
        },
        {
          title:
            "定期监测和筛查： 进行定期的健康监测，包括血液检查、心脏功能检测、癌症标志物检查等，以及早发现并及时处理潜在的问题。",
        },
        {
          title:
            "药物管理： 根据每种疾病的特点，合理使用药物来控制症状、减轻疼痛，以及延缓疾病的进展。",
        },
        {
          title:
            "预防措施： 强调疾病的预防，包括接种、定期体检、癌症筛查等，以减少患病风险。",
        },
      ],
    },
  ],
  hightD0: "",
  mediumD0: "白血病、糖尿病、肝癌、胃癌、腺癌、高血压",
  lowD0: "帕金森症，系统性红斑狼疮，心肌病",
  hightD1: "",
  mediumD1: "帕金森症，系统性红斑狼疮，心肌病",
  lowD1: "白血病、糖尿病、肝癌、胃癌、腺癌、高血压",
  hightD2:
    "帕金森、系统性红斑狼疮、心肌病、白血病、糖尿病、肝癌、胃癌、腺癌、高血压",
  mediumD2: "",
  lowD2: "",
  titleRe: "分析结果",
  titleR2: "医生建议",
  d1s: "低风险",
  d2s: "中风险",
  d3s: "高风险",
  d1sw: "低风险疾病",
  d2sw: "中风险疾病",
  d3sw: "高风险疾病",
  bb1: "白细胞计数",
  bb2: "中性粒细胞",
  bb3: "淋巴细胞",
  bb4: "单核细胞",
  bb5: "嗜酸性粒细胞",
  bb6: "嗜碱性粒细胞",
  bb7: "红细胞计数",
  bb8: "血红蛋白测定",
  bb9: "红细胞比积测定",
  bb10: "平均红细胞体积",
  bb11: "平均红细胞血红蛋白量",
  bb12: "平均红细胞血红蛋白浓度",
  bb13: "红细胞体积分布宽度测定CV",
  bb14: "血小板计数",
  bb15: "平均血小板体积测定",

  sjfx: "数据分析",
  scsj: "上传数据",
  txsj: "填写数据",
  clear: "全部清除",
  subs: "提交分析",
};
