import VueI18n from "vue-i18n";

import Vue from "vue";

import en from "./modules/en";

import zh from "./modules/zh";
import store from "@/store/index";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  messages: {
    zh: zh, // 中文语言包
    en: en, // 英文语言包
  },
});

export default i18n;
